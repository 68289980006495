import ShoppingCart from '@mui/icons-material/ShoppingCart'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, Chip, Divider, Icon, ListItem, Stack, Tooltip, Typography } from '@mui/material'
import React, {useState} from 'react'
import useWindowDimensions from "../../../hooks/windowDimensions";
import Close from '@mui/icons-material/Close';
import LyricsIcon from '@mui/icons-material/Lyrics';
import ShareIcon from '@mui/icons-material/Share';
import PinModal from './PinModal';
import * as Brands from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddComment, Favorite, FavoriteBorder } from '@mui/icons-material';
import * as API from '../../../utils/APICalls'
import * as helpers from '../../../utils/helpers'
import ShareModal from './ShareModal';

// import PandoraIcon from '../../../../public/assets/pandora-icon.svg'

const PlaylistCard = (props) => {

    const{item, index, setAudioObj,audioObj, playAudio, setPlayAudio,// setShareModalOpen,shareModalOpen,
        toggleAudio,colors, albums, trkNum, setTrkNum, listEmail,saveListen, duration,  listener, setListener,
    playAlbum, setPlayAlbum, albumNav , setAlbumNav} = props

    const [ showInfo, setShowInfo ] = useState(false)
    const [ pinModalOpen, setPinModalOpen ] = useState(false)
    const [ shareModalOpen , setShareModalOpen] = useState(false)
    const { height, width } = useWindowDimensions();

   const flavors = ['psychedelic', 'funk', 'punk', 'hip hop', 
        'rock', 'soul','pop','bluesy','electronic','noise', 'spoken word','teleplay', 'alt','🤷 misc.','dance',]
   
    const trackStatusList = {
        'jam':'#9E9E9E',
        'demo':'#9E9E9E',
        'instrumental':'#FFC107',
        'writing':'#FFEB3B',
        'vocal draft':'#03A9F4',
        'vocal comp':'#3F51B5',
        'complete':'#CDDC39',
        'streaming':'#CDDC39',
    }


    const brands = ['facebook','instagram','linkedin','upwork', 'github', 'gitlab', 'twitter', 'bandcamp','soundcloud','spotify','youtube','vimeo','amazon','tidal','youTube',
        'airbnb','uber','lyft','stackoverflow','stackexchange','pinterest','skype','etsy','codepen', 'dropbox', 'googleplay','imdb','npm','meetup','itunes','cashApp','venmo','paypal','pandora','deezer','linktree','xbox','playstation','steam','snapchat','slack','kickstarter','patreon','stripe', 
        'discord','wordpress','tiktok','whatsapp',
        'windows','apple','shopify','twitch','tumblr','trello','unity','wikipedia'
    ]

    function hasValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null) {
      return true;
    }
  }
  return false;
}
    const secsToMins = (duration)=>{
        let mins = duration / 60
        let secs = duration 
         mins = mins?.toString()?.split(".")[0]
        return `${duration > 60 ? mins : '00'} : ${duration % 60 < 10 ? '0' + duration % 60: duration % 60 }`
    }
        const linkIconCheck = (l) =>{
        // console.log('[linkIconCheck] brands?.some((k)=>l?.includes(k.toString))',brands?.find((k)=>l?.includes(k?.toString())))
        let result = brands?.find((k)=>l?.includes(k?.toString()))
        if(result){
            return result?.charAt(0).toUpperCase() + result?.slice(1);
        }else if(l?.includes('youtu.be')){
          return 'Youtube'
        }else{ return false}
    }

    const updateUserLikes = async() =>{
        try{
            const likes = listener?.likes
             let index = likes?.indexOf(item?._id)
             if (index > -1) {
                     likes?.splice(index, 1);
                    // }
                }else{
                    //TODO Add listenerId from comment likes list
                    likes.push(item?._id)
  
                }
        const res = await API.updateListener({
            ...listener,
            likes: likes
            })
                if(res){
                    setListener(res)
                }
        }catch(err){
        console.log(err)
      }


    }

     const handleLikeToggle = async () =>{
            await updateUserLikes()
           try{
            let list = item?.likes
            let index = list?.indexOf(listEmail)
    
            //   if(list?.includes(listEmail)){
                //TODO Remove userId from comment likes list
            if (index > -1) {
                    list?.splice(index, 1);
                // }
            }else{
                //TODO Add userId from comment likes list
                list.push(listEmail)
                
            }
    
            const res = await API.updateTrack({
                ...item,
                likes: list
            })
            if(res){
                
            }
            }catch(err){
        console.log(err)
      }

    }

    const itemStyles = {
        display:'flex',
        flexDirection: 'column',
        position:'relative',
        padding:'0.5rem',
        width:'100%',
        cursor:'pointer',
        // height: showInfo ?'fit-content':'4.5rem',
        height: showInfo ?'fit-content':'fit-content',
        // minHeight:'50px',
        color: colors.main,
        fontFamily: 'Gilroy, sans-serif',
        margin: '0.5rem auto',
        // border:'1px solid red'
        '&:hover': {borderRadius:'10px',
            outline:'3px dotted rgba(144, 254, 195,0.5)'
            // background:'rgba(144, 254, 195,0.5)'
        }
            }
  return (
    <ListItem sx={itemStyles}
            key={index}
            onClick={(e)=>{
                // setShowInfo(!showInfo)
                 setAudioObj(playAlbum[index])
                console.log('current audio:',audioObj)
            }}>
                <ShareModal
                    track={item}
                    album={albums[albumNav]}
                    listener={listener}
                    setListener={setListener}
                    shareModalOpen={shareModalOpen} 
                    validateEmail={helpers.validateEmail}
                    setShareModalOpen={setShareModalOpen}/>
                    <Box 
                        sx ={{ display:'flex', width:'100%', 
                        alignItems:'center',
                        justifyContent:'space-between'}}>
                            <Tooltip title='play'>

                            <PlayCircleIcon
                            className='button-woddle'
                                sx={{
                                cursor:'pointer',
                                color: colors?.main
                            }}
                            onClick={(e)=>{
                                try{
                                    if(duration){
                                        saveListen(true,'other track')
                                    }
                                    setTrkNum(index)
                                    setPlayAlbum(albums[albumNav])
                                    setAudioObj(albums[albumNav]?.tracks[index])
                                    setPlayAudio(albums[albumNav]?.tracks[index])
                                
                                toggleAudio()
                            
                                }catch(err){console.log('Track Clkic]:',err)}
                            }}
                            />
                        </Tooltip>
         <Stack sx={{ width:'55%', gap:'0.25rem'}}>

            <Typography variant='p'
            sx={{color: colors?.accent, width:'100%',fontSize:'0.9rem',}}>
                    {item?.name } {item?.isExplicit && 
                <Tooltip title="explicit">

                    <span style={{backgroundColor:'black', color:'#FFF', 
                    fontWeight:'bold', 
                    textAlign:'center',
                    alignContent:'center',
                    alignItems: 'center',
                    justifyContent:'center',
                    // fontSize:'0.5rem',
                    borderRadius:'100%',
                    padding:'0 0.25rem 0.125rem 0.125rem', 
                    width:'1.5rem',
                    height:'1.5rem'
                    }}>e</span>
                </Tooltip>
                        }
            </Typography>
            <Typography variant='p'
            sx={{color: colors?.accent, width:'100%',fontSize:'0.9rem',}}>
                    {item?.subtitle ? item?.subtitle: ''}
            </Typography>
            <Typography variant='p'
            sx={{color: trackStatusList[item?.status], 
                padding:'0.125rem 0.25rem',
                width:'fit-content',
                minWidth: '100px',
                fontSize:'0.9rem',
                backgroundColor:'rgba(176,255,252,0.2)',
                boxShadow:`inset 0 0 3px 1px ${trackStatusList[item?.status]}40,0px 0px 5px 2px ${trackStatusList[item?.status]}40`,
                border:`1px solid ${trackStatusList[item?.status]}`, 
                borderRadius:'10px'}}>
                  <b>status</b> | {item?.status}
            </Typography>

             {hasValue(item?.streaming) && (<Box sx={{position:'relative',//bottom:'0px',
                    display:'flex',
                    //border:'1px solid red', 
                        minHeight:'1.5rem',
                        alignContent:'center',
                        gap:'0.5rem',
                        padding:'0.125rem',
                        // justifyContent:'center',
                        width:'100%', margin:'auto'}}>

                    {Object.keys(item?.streaming).map((l,j)=>{
                    
                        let link = item?.streaming[`${l}`]
                        // console.log('[profile] link:', link)
                       if(linkIconCheck(link)){
                            let iconName = linkIconCheck(link)
                            // console.log('iconName:', iconName)
                            return (
                                <Tooltip title={iconName}>

                                    <div
                                    style={{
                                        // display:'flex',
                                        color:'#000',  
                                        width:'1.5rem',height:'1.5rem',
                                        '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                        // border:'1px solid blue', 
                                        borderRadius:'3px',
                                        alignContent:'center',
                                        minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                        
                                        key={j}
                                        > 
                                    <a 
                                    style={{
                                        // display:'flex',
                                        color:'#000',  
                                    
                                        // border:'1px solid blue', 
                                        borderRadius:'3px',
                                        alignContent:'center',
                                        minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                        href={link}
                                        target='_blank'
                                        key={j}
                                        > 
                                        {iconName !== 'Pandora' && <FontAwesomeIcon icon={Brands['fa'+ iconName]} key={j} />}
                                        {iconName === 'Pandora' && <img style={{height:'1.2rem'}}
                                        src={'/assets/pandora-icon.svg'} key={j} alt='pandora' />}
                            
                                    </a>
                                    </div>
                                </Tooltip>
                           )
                        }
        

                })}
            </Box>)
                }
                        {/* <Divider /> */}  
         </Stack>

         <Box sx={{position:'absolute', right:'1rem', top:'0.5rem',display:'flex', gap: '0.5rem' }}>
            <span onClick={handleLikeToggle}> {item?.likes?.length}&nbsp;{!item?.likes?.includes(listEmail)?
              <Tooltip title='like'>
                <FavoriteBorder />
              </Tooltip>
            :
            <Tooltip title='liked'>

                <Favorite sx={{color:'red'}}/>
            </Tooltip>
            
            }</span>
            {/* <AddComment /> */}
         </Box>

        <Tooltip title='share track'>
          <Box sx={{
            // display:'flex',
            //   borderRadius:'5px',
            //   width:'2rem',
            //   height:'3rem',
            //   alignContent:'center',
            //   alignItems:'center',
            //   justifyContent:'center',
            //   padding:'0.125rem',
            //   border:'1px solid #757575',
            //   boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
            //   '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                // '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'},
              }}>
            <ShareIcon 
                className='button-woddle'
                sx={{cursor:'pointer'}}
                onClick={()=>{
                setShareModalOpen(true)
                }} />
          </Box>
        </Tooltip >

        {!showInfo   ?
            <Tooltip title='lyrics'>
                <LyricsIcon 
                    className='button-woddle' 
                    onClick={(e)=>{
                    setShowInfo(!showInfo)}}
                     />
                </Tooltip>
                 :
                 <Tooltip title='close'>

                     <Close 
                     className='button-woddle'
                     onClick={(e)=>{
                        setShowInfo(!showInfo)
                    }}
                        sx={{
                            // fontSize:'0.85rem', 
                            // position:'absolute',
                            // bottom:'1.5rem', 
                            // right:'0.75rem'
                        }}
                     />
            </Tooltip>
                 }
        {secsToMins(item?.duration)}
        <Stack sx={{gap:'0.25rem',overflow:'hidden',
            fontSize:'0.75rem', alignItems:'center'}}
             onClick={(e)=>{
                setPinModalOpen(true)
            }}
            >
            <ShoppingCart 
                className='button-woddle'
                sx={{fontSize:'0.85rem', color:colors?.main}}/>
           $ {item?.price} USD
           
        </Stack>
        </Box>    
       { showInfo && <Box sx={{
            display:'flex',
            flexDirection:'column',
            gap:'0.5rem',
            positon:'relative',
            width:'90%', 
            minHeight:' 100px',
            maxHeight:'500px',
            // height:'50vh',
            height:'500px',
            overflowY:'scroll',
            // textAlign:'center',
            justifyContent:'center',
            alignContent:'center',
            padding:'0 1rem',
            margin: '0.5rem auto',
            border: `1px solid ${colors?.main}`,
            borderRadius:'5px',
            backgroundColor:'#FFF'
       }}>
             {/* <Divider /> */}
             <Box sx={{width:'100%', height:'100%', overflow:'hidden', overflowY:'scroll'}}>

             <Typography 
            //  variant='p' 
             sx={{ position:'relative',
                maxWidth:'400px', width:'100%',
            //  border:'1px solid red',
                    textAlign:"left", whiteSpace:'pre-wrap',padding:'0.5rem'}}>
                <Box sx={{height:'1rem'}}>
                    &nbsp;
                </Box>
                   <b style={{margin:'auto'}}>
                     Lyrics:
                    </b>
                    <br/>
                {item?.lyrics} 
             </Typography>
             </Box>
             <Divider />
             <Box sx={{maxWidth:'400px', display:'flex', flexDirection:'column', gap:'0.25rem'}}>

                {item?.details?.length &&  item?.details?.map((detail,i)=>{
                    return <div>{detail}</div>
                })} 
             </Box>
            
        </Box>}
        {/* <Divider sx={{width:'100%', margin: '1rem auto'}}/> */}
<PinModal pinModalOpen={pinModalOpen} setPinModalOpen={setPinModalOpen} item={item} album={albums[albumNav]}/>
{item?.flavors?.length > 0 &&  (<Box sx={{display:'flex', gap:'0.25rem', border:'1px solid #858585', padding:'0.5rem',borderRadius:'5px',flexWrap:'wrap',fontSize:'0.7rem'}}>
        <b>flavors: </b>
        { item?.flavors?.map((flavor,j)=>{
            return (
                <Chip key={j} label={flavor} size='small'
                sx={{fontSize:'0.7rem'}}/>
                )
            })
        }
    </Box>)}
                
    </ListItem>
  )
}

export default PlaylistCard