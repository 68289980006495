
export const generateRandomHex = (len)=> {
      // Create an array of hexadecimal characters
      const characters = 'ABCDEF0123456789';
      // Create an empty string to store the hex string
      let hexString = '';
      // Generate 6 random characters and add them to the hex string
      for (let i = 0; i < len; i++) {
      hexString += characters[Math.floor(Math.random() * characters.length)];
      }

      // Return the hex string
      return hexString;
      }
export const formatDateToLocal = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString(); // Formats the date and time according to the user's locale
};

 export function validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }

export const getDisplayDate = (date) => {
    const now = new Date();
    const createdAt = new Date(date);

    if (isNaN(createdAt.getTime())) {
        return 'Invalid date';
    }

    const diffInMs = now - createdAt;
    const diffInSecs = Math.floor(diffInMs / 1000);
    const diffInMins = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInSecs < 60) {
        return `${diffInSecs} secs ago`;
    } else if (diffInMins < 60) {
        return `${diffInMins} mins ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hrs ago`;
    } else if (diffInDays < 7) {
        return `${diffInDays} days ago`;
    } else {
        return createdAt.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    }
};

  export const secsToMins = (duration)=>{
        let mins = duration / 60
        let secs = duration 
         mins = mins?.toString()?.split(".")[0]
        return `${duration > 60 ? mins : '00'}:${duration % 60 < 10 ? '0' + Math.round(duration % 60):Math.round(duration % 60)}`
    }

 export const splitKey = (string,sym,rep) =>{
         return string.split(sym).join(rep)
     }

export const itemNameFilter = (item,term) =>{
         return item?.name?.includes(term);
     }

 export const itemFieldFilter = (item,field,term) =>{
         const field_value = item[field?.toString()]
         if (field_value){

             if(item[field]?.includes(term))
                return true
            else return false 
         }
        else return false
     }

export const orderColorCheck = (status) =>{
   switch(status){

     case'draft':
      return 'grey'
      break;
     case'cart':
      return 'orange'
     break;
     case'complete':
      return 'green'
     break;
     case'hold':
      return 'red'
     break;
     case'processing':
      return 'orange'
     break;
     case'printing':
      return 'blue'
     break;
     case'fulfilled':
      return 'green'
     break;
     case'shipped':
      return 'limegreen'
     break;
   }
  }